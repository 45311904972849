import React, { Component } from 'react';
  import '../components/features.css';
  import { Row, Col } from 'react-bootstrap';
  import Button2 from '../components/button2.js';
import pic1 from '../images/pic1.png';
import pic2 from '../images/picc2.png';
import pic3 from '../images/pic3.png';
import pic4 from '../images/pic4.png';
import pic5 from '../images/pic5.png';
import pic6 from '../images/pic6.png';
import pic7 from '../images/pic7.png';
import pic8 from '../images/pic8.png';
import pic9 from '../images/pic9.png';
import pic10 from '../images/pic10.png';
import pic11 from '../images/pic11.png';
import pic12 from '../images/pic12.png';
import {Helmet} from "react-helmet";
import HU from '../components/hu-HU_lang.js';
import EN from '../components/en-EN_lang.js';
import Layout from "../components/layout";

class Features extends Component{
  constructor(props){
    super(props)
    this.state={
      input: "",
      data:[],
      scrolling: true
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('scroll', () => {
      const scrolling = window.scrollY < 100;
      if (scrolling !== this.state.scrolling) {
          this.setState({ scrolling })
      }
    });
  }
 
render(){
  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined`) {
      return "hu-HU";
    }
  
    const lang = navigator && navigator.language && navigator.language.split("-")[0];
    if (!lang) return "en";
  
    switch (lang) {
      case "hu":
        return "hu-HU";
      default:
        return "en";
    }
  };
  //console.log(this.state.scrolling);
  var userLang = getRedirectLanguage();
    return(
        <Layout>
      <div className={this.state.scrolling ? "otherPageDiv" : "otherPageDivScrolling"}>
      <div className="titleDiv">
        <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functions : EN.functions}</h2>
        <Helmet>
          <title>Funkciók - Tekintse meg az IT Üzemeltetés automatizálás nyújtott funkciókat</title>
          <meta name="description" content="Automatizálja a szolgáltatásmenedzsmentet, az Automeasify segítségével egyszerűen automatizálhatja a szolgáltatásmenedzsmentet és az IT-infrastruktúra működését az ITIL módszertana keretében. Az IT-infrastruktúra működtetésének automatizálásával tudjuk, hogy a teljes IT-infrastruktúra kezelése nehéz és időigényes lehet. Az Automeasify segítségével könnyedén kezelheti konfigurációit, kiszolgálóit és alkalmazásait az infrastruktúrában. Automatizálja a napi ismétlődő feladatokat, minden csapatnak vannak olyan feladatai, amelyeket naponta kell elvégezni. De miért pazarolja az idejét ezekre a feladatokra, ha automatizálhatja az Automeasify segítségével?" />
          <meta property="og:title" content="Funkciók - Tekintse meg az IT Üzemeltetés automatizálás nyújtott funkciókat" />
          <meta property="og:type" content="website" />
          <meta property="og:description " content="Automatizálja a szolgáltatásmenedzsmentet, az Automeasify segítségével egyszerűen automatizálhatja a szolgáltatásmenedzsmentet és az IT-infrastruktúra működését az ITIL módszertana keretében. Az IT-infrastruktúra működtetésének automatizálásával tudjuk, hogy a teljes IT-infrastruktúra kezelése nehéz és időigényes lehet. Az Automeasify segítségével könnyedén kezelheti konfigurációit, kiszolgálóit és alkalmazásait az infrastruktúrában. Automatizálja a napi ismétlődő feladatokat, minden csapatnak vannak olyan feladatai, amelyeket naponta kell elvégezni. De miért pazarolja az idejét ezekre a feladatokra, ha automatizálhatja az Automeasify segítségével?" />
          <meta property="og:url" content="https://automeasify.com/funkciok" />
          <meta property="og:image" content="https://blingdy.app/uploads/banner828_7146d1f5a9.png" />
        </Helmet>
      </div>
      <div className="contentDiv">
        <Row className="contentDivFyler2">
        <Row className="featuresRow">
            <Col xl={6} md={6} xs={12}>
                <div className="feautersRight2">
                    <Row>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox12">
                          <img src={pic1} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle1 : EN.functionsBoxTitle1}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent1 : EN.functionsBoxContent1}</p>
                        </div>
                      </Col>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox12">
                          <img src={pic4} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle2 : EN.functionsBoxTitle2}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent2 : EN.functionsBoxContent2}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox12">
                          <img src={pic3} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle3 : EN.functionsBoxTitle3}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent3 : EN.functionsBoxContent3}</p>
                        </div>
                      </Col>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox12">
                          <img src={pic2} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle4 : EN.functionsBoxTitle4}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent4 : EN.functionsBoxContent4}</p>
                        </div>
                      </Col>
                    </Row>
                </div>
              </Col>
              <Col xl={6} md={6} xs={12}>
                <div className="feautersLeft2">
                    <h4>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsHead1 : EN.functionsHead1}</h4>
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsContent1 : EN.functionsContent1}</h3>
                    <Button2 />
                </div>
              </Col>
              
            </Row>
          <Row className="featuresRow">
              <Col xl={6} md={6} xs={12}>
                <div className="feautersLeft">
                    <h4>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsHead2 : EN.functionsHead2}</h4>
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsContent2 : EN.functionsContent2}</h3>
                    <Button2 />
                </div>
              </Col>
              <Col xl={6} md={6} xs={12}>
                <div className="feautersRight">
                    <Row>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox1">
                          <img src={pic8} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle5 : EN.functionsBoxTitle5}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent5 : EN.functionsBoxContent5}</p>
                        </div>
                      </Col>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox1">
                          <img src={pic6} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle6 : EN.functionsBoxTitle6}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent6 : EN.functionsBoxContent6}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox1">
                          <img src={pic7} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle7 : EN.functionsBoxTitle7}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent7 : EN.functionsBoxContent7}</p>
                        </div>
                      </Col>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox1">
                          <img src={pic5} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle8 : EN.functionsBoxTitle8}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent8 : EN.functionsBoxContent8}.</p>
                        </div>
                      </Col>
                    </Row>
                </div>
              </Col>
            </Row>
            <Row className="featuresRow">
            <Col xl={6} md={6} xs={12}>
                <div className="feautersRight2">
                    <Row>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox12">
                          <img src={pic11} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle9 : EN.functionsBoxTitle9}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent9 : EN.functionsBoxContent9}</p>
                        </div>
                      </Col>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox12">
                          <img src={pic10} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle10 : EN.functionsBoxTitle10}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent10 : EN.functionsBoxContent10}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox12">
                          <img src={pic12} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle11 : EN.functionsBoxTitle11}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent11 : EN.functionsBoxContent11}</p>
                        </div>
                      </Col>
                      <Col xl={6} md={6} xs={12}>
                        <div className="flyerBox12">
                          <img src={pic9} />
                          <h2>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxTitle12 : EN.functionsBoxTitle12}</h2>
                          <p>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsBoxContent12 : EN.functionsBoxContent12}</p>
                        </div>
                      </Col>
                    </Row>
                </div>
              </Col>
              <Col xl={6} md={6} xs={12}>
                <div className="feautersLeft2">
                    <h4>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsHead3 : EN.functionsHead3}</h4>
                    <h3>{userLang === "hu-HU" || userLang === "hu-hu" ? HU.functionsContent3 : EN.functionsContent3}</h3>
                    <Button2 />
                </div>
              </Col>
              
            </Row>
        </Row>
      </div>
  </div>
</Layout>
    );
  }
}
export default Features